@value variables: '../../../variables.module.scss';
@import  '../../../mixins.module.scss';
@value mobileViewWidth from variables;
$isMobile: mobileViewWidth;
$backgroundTransition: background-color 0.2s linear;

.detailed {
  height: 100vh;
  display: flex;
  flex-direction: column;

  @include desktop {
    height: auto;
  }

  @include tablets-and-mobile {
    height: auto;
  }
}

.head {
  position: relative;
  padding: 60px 80px 0 0;
  height: 625px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  transition: $backgroundTransition;

  @include desktop {
    height: auto;
    min-height: 625px;
  }
  
  @include tablets-and-mobile {
    padding-top: 90px;
    min-height: auto;
    height: auto;
    padding-right: 50px;
  }

  @include mobiles-only {
    padding-right: 20px;
  }

  .year {
    font-family: Salome;
    position: absolute;
    left: 20%;
    background: #000;
    font-size: 40px;
    color: #fff;
    top: 0;
    padding: 70px 60px 20px;
    z-index: 10;
    white-space: nowrap;

    @include tablets-and-mobile {
      left: 50%;
      transform: translateX(-50%);
      font-size: 30px;
      padding-top: 20px;
    }
  }

  .left {
    width: 44%;
    max-width: 500px;
    display: inline-block;
    position: relative;
    padding-top: 100px;

    @include mobiles-only{
      padding-top: 20px;
    }

    .photo {
      max-width: 100%;
      max-height: 60%;
      position: absolute;
      bottom: 0;
      
     &.leftside {
        left: 0;

      }
      
      &.rightside {
        right: 0;
      }

      &.mirrored {
        display: none;
      }
      
      @include mobiles-only {
        &:not(.isMobile) {
          display: none;
        }
        
        &.isMobile {
          display: block;
          left: 0;
          right: auto;
        } 
      }
    }

    .texture {
      width: 95%;
      display: block;
      position: relative;

      @include mobiles-only {
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .right {
    width: 56%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 50px;

    .names {
      font-family: Salome;
      font-size: 72px;
      margin: 0;
      line-height: 1.1;
      font-weight: normal;

      &.long {
        font-size: 57px;
      }

      @include tablets-and-mobile {
        font-size: 48px;

        &.long {
          font-size: 40px;
        }
      }
    }

    .inventionTitle {
      font-family: "Qualcomm Semibold";
      font-size: 30px;
      margin-bottom: 0;
      font-weight: normal;
      
      @include tablets-and-mobile {
        font-size: 25px;
      }
    }

    .description {
      font-family: Qualcomm;
      font-size: 22px;
      padding-bottom: 75px;

      @include tablets-and-mobile {
        font-size: 20px;
      }

      p {
        margin-bottom: 0;
        margin-top: 10px;
      }

    }
  }
}

.middle {
  flex: 1 1 auto;
  padding: 50px 80px;
  background: #000;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;

  @include desktop {
    min-height: 100vh;
    padding-bottom: 150px;
  }

  @include tablets-and-mobile {
    padding: 70px 20px;
    display: block;
  }
  
  @include tablets-only {
    padding: 70px 50px;
  }

  .title {
    margin-bottom: 50px;
    font-size: 30px;
    font-family: "Qualcomm Semibold";
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: bold;

    @include tablets-and-mobile {
      margin-bottom: 15px;
    }
  }

  .left {
    padding-bottom: 30px;
    width: 65%;
    padding-right: 10%;
    position: relative;

    .name {
      font-family: "Qualcomm Semibold";
      font-size: 28px;
    }

    .scrollBar {
      position: absolute;
      right: 0;
      top: 90px;
      bottom: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include desktop {
        right: 40px;
      }

      @include laptops-only {
        right: 10px;
      }

      @include tablets-and-mobile {
        display: none;
      }
      
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top:0;
        bottom: 0;
        left: 50%;
        width: 5px;
        border: solid #808080;
        border-width: 1px 0 1px 1px;
      }

      &::before {
        transform: rotate(180deg) translateX(calc(100% - 1px));
      }

      .arrowTop,
      .arrowBottom {
        margin: 10px 30px 10px 31px;
        border-color: #808080;
        background-color: #000;
        z-index: 1;
        width: 45px;
        height: 45px;

        img {
          top: 12px;
          left: 0;
        }
      }

      .arrowTop {
        img {
          top: 9px;
        }
      }
    }

    .invention {
      max-height: 740px;
      margin-bottom: 50px;
      overflow-y: hidden;
      font-size: 16px;
      line-height: 1.5;

      p:first-child {
        font-size:20px;
        margin-top: 0;
      }

      @include tablets-and-mobile {
        max-height: none;
        overflow-y: auto;

        p:first-child {
          width: 100%;
          margin: 10px 0;
        }
      }
    }

    .womenList {
      position: absolute;
      bottom: 30px;

      .item {
        font-family: Salome;
        font-weight: bold;
        margin-right: 30px;
        display: inline-block;

        &:not(.current) {
          text-decoration: underline;
          color: white !important;
        }
      }
    }

    .arrows {
      width: 350px;
      margin-left: -50px;
    }

    @include tablets-and-mobile {
      width: auto;
      max-height: none;
      height: auto;
      padding-right: 0;
    
      .invention {
        max-height: none;
      }

      .womenList {
        bottom: auto;
        top: -50px;
        width: 100%;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
    
        .item {
          margin: 0 5px;
        }
      }
    }
  }

  .right {
    position: relative;
    z-index: 100;

    .patentImage {
      img {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        max-width: 30vw;
        max-height: 75vh;

        &.visible {
          position: relative;
          opacity: 1;
        }
      }
    }

    @include tablets-and-mobile {   
      .patentImage {
        text-align: center;
    
        img {
          max-width: 70vw;
          max-height: none;
        }
      }
    }
  }

  .inventionTexture {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    
    &,
    img {
      object-fit: contain;
      max-width: 440px;
      max-height: 440px;
    }

    @include tablets-and-mobile {
      display: none;
    }
  }
}

.arrows {
  margin-top: 40px;
  font-size: 18px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowLeft, .arrowRight, .arrowTop, .arrowBottom {
  border: 1px solid #ccc;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  margin: 0 20px;
  text-align: center;
  cursor: pointer;

  img {
    width: 20px;
    position: relative;
    top: 7px;
    left: -2px;
  }
}

.arrowRight {
  left: initial;
  right: 50px;

  img {
    left: 2px;
  }
}

.bottom {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 50px 20px 80px;
  min-height: 250px;
  position: relative;
  transition: $backgroundTransition;

  @include desktop {
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
  }


  @include tablets-and-mobile {
    padding: 40px 10px;
    min-height: auto;
    flex-direction: column-reverse;
    align-items: center;
  }

  .back {
    flex: 0 1 auto;
    position: relative;

    @include desktop {
      top: -20px;
    }

    a {
      text-decoration: none;
      color: white;
      background: black;
      padding: 12px 30px;
      border: 1px solid #fff;
      border-radius: 100px;
      display: block;
      white-space: nowrap;
    }
  }

  .slider {
    margin-left: 40px;

    @include tablets-and-mobile {
      margin: 0 auto;
      flex: 1 1 auto;
    }
  }
}

@media screen and (max-width: $isMobile) {
  .detailed {
    display: block;
  }

  .head {
    display: block;
    padding-right: 0;
    padding-left: 0;

    .left {
      width: 100%;
      position: relative;
      max-width: none;

      .photo {
        max-width: 100%;
        max-height: 90%;
        position: absolute;
        right: 0;
        left: initial;
        bottom: 0;
        transform: none;
      }

      img {
        display: block;
      }
    }

    .right {
      width: initial;
      padding: 50px 20px 20px;

      .inventionTitle {
        max-width: 60vw;
      }

      .names {
        font-size: 48px;
      }
    }
  }

  .bottom {
    padding: 50px 10px 50px;
  }
}
