@mixin desktop {
    @media screen and (min-device-width: 1025px) and (orientation: landscape) { @content; }
}
  
@mixin laptops-only {
    @media screen and (min-device-width: 1025px) and (max-device-width: 1440px) and (orientation: landscape) { @content; }
}

@mixin tablets-and-mobile {
    @media screen and (max-device-width: 1024px) { @content; }
}

@mixin tablets-only {
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) { @content; }
}

@mixin mobiles-only {
    @media screen and (max-device-width: 767px) { @content; }
}